import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { action, observable, computed } from 'mobx';
import styled from 'styled-components';

import Rate from './Rate';
import Contract from './Contract';
import Traveller from './Traveller';

import {
  PaymentPicker, PaymentInput, PaymentInfo
} from 'components/forms/PaymentField';

import { Delete as DeleteIcon } from 'components/icons';

const Number = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  color: #4a515c;
  font-size: 13px;
  font-weight: bold;
  line-height: 22px;

  &:after {
    content: '.';
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & + & {
    margin-top: 10px;
  }

  ${Contract} {
    width: 178px;
    flex-shrink: 0;
  }

  ${Rate} {
    min-width: 280px;
    flex-grow: 0;
  }

  ${PaymentInfo}, ${Actions} {
    min-width: 54px;
    flex-grow: 0;
  }

  ${PaymentInput} {
    flex-grow: 1;
  }

  & > div {
    margin-left: 14px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const Details = styled.div`
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  background-color: #F4F7FA;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 25px;

  & + & {
    margin-top: 20px;
  }

  ${Row} + ${Details} {
    margin-top: 20px;
  }
`;

@inject('bookingForm')
@observer
class Slot extends React.Component {
  static propTypes = {
    number: PropTypes.number.isRequired,
    field: PropTypes.object.isRequired,
    rateOptions: PropTypes.array,
    bookingForm: PropTypes.object.isRequired,
    className: PropTypes.string
  }

  static defaultProps = {
    className: '',
    rateOptions: []
  }

  @observable hovered = false;

  @action
  setHovered() {
    this.hovered = true;
  }

  @action
  unsetHovered() {
    this.hovered = false;
  }

  @computed get hasRemove() {
    const { bookingForm: { isEditableMode, selectedCount } } = this.props;
    return isEditableMode && selectedCount > 1;
  }

  handleMouseEnter = (e) => {
    e.preventDefault();

    if (this.hasRemove) {
      this.setHovered();
    }
  }

  handleMouseLeave = (e) => {
    e.preventDefault();

    if (this.hasRemove) {
      this.unsetHovered();
    }
  }

  handleRemove = (e) => {
    e.preventDefault();

    const { field, bookingForm } = this.props;
    bookingForm.removeSlot(field);
  }

  render() {
    const {
      bookingForm: { tariff },
      number,
      field,
      field: { hasTraveller, traveller },
      rateOptions,
      className
    } = this.props;

    const { hovered, handleMouseEnter, handleMouseLeave } = this;

    return (
      <Wrapper
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Number>
          {number}
        </Number>

        {hasTraveller && (
          <Row>
            <Traveller
              traveller={traveller}
            />
          </Row>
        )}

        <Row>
          <Rate
            slot={field}
            rateOptions={rateOptions}
          />

          <Contract
            field={field.$('contract')}
          />

          <PaymentInput
            field={field}
            tariff={tariff}
          />

          {hovered && (
            <Actions
              onClick={this.handleRemove}
            >
              <DeleteIcon />
            </Actions>
          )}

          {!hovered && (
            <PaymentInfo
              field={field}
              tariff={tariff}
            />
          )}
        </Row>

        {field.isEdit && (
          <Details>
            <PaymentPicker
              field={field}
              tariff={tariff}
            />
          </Details>
        )}
      </Wrapper>
    );
  }
}

export default styled(Slot)``;
